    import React from 'react'; 
    // import SidebarShop from "./SidebarShop";
    import Products from "./Products";
    function Shop() {     
        return (            
         <>
          <main class="mx-auto max-w-7xl px-5 lg:px-7">
          <div class="grid pt-6 bg-white">
            <nav aria-label="Breadcrumb">
              <ol className="mx-auto flex max-w-2xl items-center space-x-2 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <li className="text-sm">
                  <a href="/shop" aria-current="page" className="font-medium text-gray-500 hover:text-gray-600">
                    Shop
                  </a>
                </li>
              </ol>
            </nav>
            </div>
            <div class="grid">
                <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-16 lg:max-w-7xl lg:px-8">
                  <h2 className="text-2xl font-bold tracking-tight text-gray-900">Shop for your custom prints here. Inquire into each item using our form or contact method.</h2>

                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                    {Products.map((product) => (
                      <div key={product.id} className="group relative">
                        <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                          <img
                            alt={product.imageAlt}
                            src={product.imageSrc}
                            className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                          />
                        </div>
                        <div className="mt-4 flex justify-between">
                          <div>
                            <h3 className="text-sm text-gray-700">
                              {/* <a href={product.href}> */}
                                <span aria-hidden="true" className="absolute inset-0" />
                                {product.name}
                              {/* </a> */}
                            </h3>
                          </div>
                          <p className="text-sm font-medium text-gray-900">{product.price}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div> 
          </main></>
         ); 
    } 
    export default Shop;