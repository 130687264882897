import React from 'react'; 
function Home() {     
    return (         
       
<>    
<main>
    <div className=" py-24 sm:py-32 mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:text-center">
        <h2 className="text-base font-semibold leading-7 text-indigo-600">Trusted printing services</h2>
        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          All Prints. All your large format and direct to frame print needs.
        </p>
        <p className="mt-6 text-lg leading-8 text-gray-600">
          Call, message or stop by our main location to see how we can help you.
        </p>
      </div>
    </div>
</main></>
    ); 
} 
export default Home;