import React from 'react';
import ReactDOM from "react-dom/client";
import './css/App.css';
import './css/index.css';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Shop from "./pages/Shop/Shop";
import Contact from "./pages/Contact";
import NoPage from "./pages/NoPage";
import Detail from "./pages/Shop/Detail/Detail";
import reportWebVitals from './reportWebVitals';

export default function Index() {
  return ( <BrowserRouter> <Routes> <Route path = "/"
      element = {
          <Layout/>
      } > <Route index element = {
          <Home/>
      }
      />           <Route path="shop" element={<Shop />
  }
  />           <Route path="shop/detail/:id" element={<Detail />
  }
  /><Route path="contact" element={<Contact />
}
/>           <Route path="*" element={<NoPage />
}
/>         </Route> 
</Routes>    
 </BrowserRouter> 
 );
}

// ReactDOM.render( < App / > , document.getElementById("root"));
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();