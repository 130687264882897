import React from 'react'; 
function Contact() {     
    return (              
<>    
<main>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2877.5454353151854!2d-79.36240422364074!3d43.844522371093724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d4bfbc993a45%3A0x5ca2ae9ed7d002bb!2s250%20Cochrane%20Dr%20%231%2C%20Markham%2C%20ON%20L3R%208E5!5e0!3m2!1sen!2sca!4v1725478977040!5m2!1sen!2sca" title="Location Map" width="100%" height="450px" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  <div class="mx-auto grid max-w-7xl py-6 sm:px-6 lg:px-8">    
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
      <div className="max-w-xl lg:max-w-lg">
        <h3 className="text-xl font-bold tracking-tight sm:text-4xl">Operation Hours</h3>
                  <p className="mt-4 text-lg leading-8 text-gray">
                    Monday - Friday 10AM - 5PM
                    Appointments, Scheduled Pickups and Rush Services available.
                  </p>
                  <div className="mt-6 flex max-w-md gap-x-4">
                  </div>
                </div>
                <div className="max-w-xl lg:max-w-lg">
                  <h3 className="text-xl font-bold tracking-tight sm:text-4xl">Contact Us</h3>
                  <p className="mt-4 text-lg leading-8 text-gray">
                    Please use the following form to contact us with your inquiry.
                  </p>
                  <div className="mt-6 flex max-w-md gap-x-4">
                  <iframe title="InquiryForm" width="640px" height="480px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=XARwK5gm9UKSSKyj7IKDDS3YHONPdmdAgQo12GQbVKdUOERKSVlCVUdRNUVFNU9aRktRSDA0OTM2Ti4u&amp;embed=true" frameborder="0" marginwidth="0" marginheight="0"> </iframe>
                  </div>
                </div>    
      </div>
  </div>
</main></>
); 
} 
export default Contact;