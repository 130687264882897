import React from "react";
import {
    Outlet
} from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
const Layout = () => {
        return ( <> <Navbar />
        <section>
            <div className="hero is-primary bg-white">
            <div className="hero-body">
                <Outlet />
                </div>
            </div>   
        </section>     
        <Footer /> </>   ); 
    }; 
export default Layout;